import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";

import * as actions from "../../../actions";

import QUDOServer from "../../../helpers/QUDOServerConnection";
import {sendFacebookPixelEvent} from "../../../helpers/FacebookPixel";

import { ButtonInput } from "../forms/buttons";
import { DropdownInput } from "../forms/dropdowns";
import { UsernameInput, PasswordInput, EmailInput, AccNameInput, ReferralInput} from "../forms/text";

import { MessageModal } from "./modals";
import { topMetaModal } from "./MetaModals";

//const randomizeIcon = require("../../../images/definitive/QUDO_RandomizeIcon.png");

export function AccountNameFields(props) {
	const [message, setMessage] = useState('');

	const [updateAccountname, setUpdateAccountname] = useState(false);
	const [randomizeAccountName, setRandomizeAccountName] = useState(false);

	//When checkboxes change re-check accountname validity
	useEffect(() => {
		setUpdateAccountname(!updateAccountname);
	}, [props.newAccount]);

	//Reset button state (button not doing this for some reason)
	useEffect(() => {
		if(randomizeAccountName) {
			setRandomizeAccountName(false);
		}
	}, [randomizeAccountName]);

	//Check when the parent component forces the account name randomization
	useEffect(() => {
		if(props.randomizeAccountName) {
			setRandomizeAccountName(true);
			props.setRandomizeAccountName(false);
		}
	}, [props.randomizeAccountName]);

	//Show a warning when user says he wants to use his own TELOS account
	useEffect(() => {
		if(!props.newAccount) {
			setMessage(
				'ATTENTION: You must have an already existing TELOS account and have permissions to use it!'
			);
		}
	}, [props.newAccount]);

	return(
		<div className="my-3" style={{display: process.env.REACT_APP_TELOS_ENVIRONMENT !== 'testnet' ? "inherit" : "none"}}>
			<div className="row">
				<div
					className="col-12"
					style={{marginBottom: '1rem'}}
				>
					<h5>
						Do you have a TELOS account?
					</h5>
				</div>

				<div
					className="col-12"
					style={{marginBottom: '1rem'}}
				>
					<div
						className="row"
						onClick={() => props.setNewAccount(!props.newAccount)}
					>
						<input
							type="checkbox"
							className="col-2 my-auto"
							checked={props.newAccount}
							// onChange={() => props.setNewAccount(!props.newAccount)}
						/>
						<div className="col-9">
							No, create a new TELOS account
						</div>
					</div>
				</div>

				<div
					className="col-12"
					style={{marginBottom: '1rem'}}
				>
					<div
						className="row"
						onClick={() => props.setNewAccount(!props.newAccount)}
					>
						<input
							type="checkbox"
							className="col-2 my-auto"
							checked={!props.newAccount}
							// onChange={() => props.setNewAccount(!props.newAccount)}
						/>
						<div className="col-9">
							Yes, I will add my own TELOS account
						</div>
					</div>
				</div>
			</div>

			<div className="row">
				<div className={`${props.newAccount ? "col-8" : "col-12"}`}>
					<AccNameInput
						set={props.set}
						setValid={props.setValid}
						username={props.username}
						newAccount={props.newAccount}
						defaultAccountName={props.newAccountName}
						checkAccountnameValidity={updateAccountname}
						randomizeAccountName={randomizeAccountName}
					/>
				</div>

				{props.newAccount && (
					<div className="col-4 p-0 text-center">
						<ButtonInput
							trigger
							initial={false}
							set={setRandomizeAccountName}
							className="form-submit-btn text-center mt-0 mx-0"
							style={{padding: '0px'}}
							label={
								<div>
									{/*}
									<img
										alt="Randomize TELOS account name"
										src={randomizeIcon}
										style={{maxWidth: '35px', maxHeight: '35px'}}
									/>
									{*/}
									<div>
										Randomize
									</div>
								</div>
							}
						/>
					</div>
				)}
			</div>

			<MessageModal
				show={message}
				message={message}
				hide={() => setMessage('')}
			/>
		</div>
	);
}

export default function Register(props) {
	const availableTypes = [
		{label: "Gamer", key: process.env.REACT_APP_USER_TYPE},
		{label: "Game Developer", key: process.env.REACT_APP_GAMEDEV_TYPE},
		{label: "Service Provider", key: process.env.REACT_APP_PROVIDER_TYPE},
		{label: "Gaming Guild", key: process.env.REACT_APP_GUILD_TYPE}
	];

	const dispatch = useDispatch();
	const currentUser = useSelector(state => state.info);

	const [disabled, setDisabled] = useState(true);
	const [type, setType] = useState(availableTypes[0].key);
	const [username, setUsername] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [passwordConf, setPasswordConf] = useState("");
	const [passwordsMismatchMsg, setPasswordsMismatchMsg] = useState(null);
	const [accName, setAccName] = useState("");
	const [randomizeAccountName, setRandomizeAccountName] = useState(false);
	const [newAccName, setNewAccName] = useState(true);
	const [validAccName, setValidAccName] = useState(false);
	const [updateUsername, setUpdateUsername] = useState(true);

	// const [displayName, setDisplayName] = useState("");
	// const [validDisplayName, setValidDisplayName] = useState(false);
	const [newsletter, setNewsletter] = useState(false);
	const [mandatoryNewsletter, setMandatoryNewsletter] = useState(false);
	// const [minimumAge, setMinimumAge] = useState(false);
	
	const [referralUsername, setReferralUsername] = useState("");
	const [referralMsg, setReferralMsg] = useState("");

	const [termsAndConditions, setTermsAndConditions] = useState(false);
	const [showNewsletterErrors, setShowNewsletterErrors] = useState(false);
	const [adSource, setAdSource] = useState("");

	useEffect(() => {
		const url = new URL(window.location.href);
		const params = new URLSearchParams(url.search);

		const register = params.get('register');
		const userType = params.get('type');
		const referral = params.get('referral');
		const adSource = params.get('adsource');

		if(register) {
			if(userType) {
				const validType = availableTypes.find(e => {
					return e.key === userType;
				});

				if(validType) {
					setType(userType);
				}
			}

			if(referral) {
				setReferralUsername(referral);
			}
		}
		if(adSource){
			setAdSource(adSource);
		}

	}, []);

	//When user type changes re-check username validity
	useEffect(() => {
		//console.log(type);
		setUpdateUsername(!updateUsername);
	}, [type]);

	useEffect(() => {
		if(global.isDev) console.log("adSource: ", adSource);
	}, [adSource]);

	useEffect(() => {
		if(passwordConf && (password !== passwordConf)) {
			setPasswordsMismatchMsg("Passwords don't match");
		} else {
			setPasswordsMismatchMsg("");
		}

		if(password === passwordConf && username && password && email) {
			setDisabled(false);
		} else {
			setDisabled(true);
		}
	}, [username, password, passwordConf, email, accName]);

	function close() {
		//These fields need to be manually reset
		// setMinimumAge(false);
		setNewsletter(false);
		setMandatoryNewsletter(false);
		setTermsAndConditions(false);
		setReferralUsername('');
		setType(availableTypes[0].key);

		props.setShow(null);
	}

	async function F_randomizeAccountName() {
		const url = process.env.REACT_APP_QUDO_SERVER + '/api/randomaccname';
        const maxTries = 5;
		let _username = undefined;

        for(let tries = 0; tries < maxTries; tries++) {
            await QUDOServer.get(url, {
                params: {username: username},
                headers: {"Content-Type": "application/json"},
                json: true,
                withCredentials: true
            })
            .then(data => {
				console.log("Randomized username: ", data.data.username)
                setAccName(data.data.username);
				setValidAccName(true);
				tries = maxTries;
				_username = data.data.username;
            })
            .catch(error => {
                console.log(error);
            });
        }
		return _username;
    }

	async function submit() {
		sendFacebookPixelEvent('trackCustom', 'CompleteRegistration', null);

		let _accName = accName;
		let _validAccName = validAccName;
		console.log("Registering- initial name: ", _accName, _validAccName)

		if(disabled) {
			console.log("Registering disabled")
			return;
		}

		// Do not show the testnet account name randomization on testnet, so we have this here to create the username due to async nature of the function and useState
		if(process.env.REACT_APP_TELOS_ENVIRONMENT === 'testnet' && !_validAccName){
			await F_randomizeAccountName().then((result) => {
				_accName = result;
				_validAccName = true;
				console.log("Registering- testnet name: ", _accName, _validAccName)
			});
		}

		if(!_validAccName) {
			console.log("Invalid account name")
			return;
		}

		if(!termsAndConditions || !mandatoryNewsletter) {
			setShowNewsletterErrors(true);
			return;
		}	

		let body = {
			type: type,
			email: email,
			username: username,
			// display_name: displayName,
			password: password,
			accountName: _accName,
			newAccountName: newAccName,
			referralUsername: referralUsername,
			newsletter: newsletter,
			// minimumAge: minimumAge,
			deviceID: process.env.REACT_APP_WEBAPPUUID,
			adSource: adSource
		}
		
		QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/auth/register`, body, {
			headers: {"Content-Type": "application/json"},
			json: true,
			withCredentials: true
		})
		.then((result) => {
			//These fields need to be manually reset
			// setMinimumAge(false);
			setNewsletter(false);
			setMandatoryNewsletter(false);
			setTermsAndConditions(false);
			setType(availableTypes[0].key);

			dispatch(actions.fetchUser());

			props.success("Almost there! You just need to verify your email.");
		})
		.catch((error) => {
			//These fields need to be manually reset
			// setMinimumAge(false);
			setNewsletter(false);
			setMandatoryNewsletter(false);
			setTermsAndConditions(false);
			setType(availableTypes[0].key);

			props.failure(error.errorMessage);
			console.log("register:", error);
		});
	}

	if(currentUser) {
		return null;
	}

	return(
		<Modal 
			show={props.show}
			onHide={close}
			dialogClassName="meta-box"
		>
			<Modal.Body className="scroll-95 pt-0">
				{topMetaModal(props.setShow)}

				<div className="row meta-body">
					<div className="col-12 login-main-text">
						Welcome to the
						<br />
						QUDO World!
					</div>

					<div className="col-12">
						<DropdownInput 
							label="Are you a..."
							initial={availableTypes.find(e => e.key === type).label}
							choices={availableTypes}
							set={setType}
						/>

						<UsernameInput
							type={type}
							unique={true}
							set={setUsername}
							setRandomizeAccountName={setRandomizeAccountName}
							checkUsernameValidity={updateUsername}
							onBlur={() => {
								//If the TELOS account name is empty or invalid, automatically fill it
								if(!accName && newAccName) {
									setRandomizeAccountName(true);
								}
							}}
						/>

						{/*}
						<DisplayNameInput
							placeholder="Display name (optional)"
							value={displayName}
							set={setDisplayName}
							setValid={setValidDisplayName}
							userType={type}
							checkDisplayNameValidity={updateUsername}
						/>
						{*/}

						<EmailInput 
							set={setEmail}
							validate={true}
							userType={type}
						/>
						<div className="email-warning">
							Please, make sure your email is correct,
							otherwise you won't be able to claim your rewards
						</div>

						<PasswordInput 
							key={1} 
							placeholder="Password"
							set={setPassword}
							validate={true}
						/>

						<PasswordInput 
							key={2} 
							placeholder="Confirm password"
							set={setPasswordConf} 
							//validate={true}
							forceInvalid={passwordsMismatchMsg}
						/>

						<AccountNameFields 
							set={setAccName}
							username={username}
							newAccount={newAccName}
							setNewAccount={setNewAccName}
							setValid={setValidAccName}
							randomizeAccountName={randomizeAccountName}
							setRandomizeAccountName={setRandomizeAccountName}
						/>

						{/*}
						<div className="row mt-1">
							<div className="col-10">
								I am at least 18 years old
							</div>
							<input
								type="checkbox"
								className="col-2 my-auto"
								checked={minimumAge}
								onChange={() => setMinimumAge(!minimumAge)}
							/>
						</div>
						{*/}

						{type !== process.env.REACT_APP_GUILD_TYPE && (
							<div>
								<h5 className="mb-2">
									Referral
								</h5>
								<ReferralInput
									placeholder="Referral username (optional)"
									value={referralUsername}
									set={setReferralUsername}
									forceInvalid={referralMsg}
									setForceInvalid={setReferralMsg}
								/>
							</div>
						)}

						<div>
							<h5 className="mb-2">
								Terms and Conditions
							</h5>
							<div
								className="row"
								onClick={() => setTermsAndConditions(!termsAndConditions)}
							>
								<input
									type="checkbox"
									className="col-2 my-auto"
									checked={termsAndConditions}
								/>
								<div className="col-10">
									I agree with the {" "}
									<a
										target="_blank"
										rel="noopener noreferrer"
										href="https://qudo.io/documents/terms_and_conditions.html"
										className="d-inline-block"
									>
										Terms and Conditions
									</a>
									{" "} and the {" "}
									<a
										target="_blank"
										rel="noopener noreferrer"
										href="https://qudo.io/documents/privacy_policy.html"
										className="d-inline-block"
									>
										Privacy Policy.
									</a>
								</div>
							</div>
							{showNewsletterErrors && !termsAndConditions && (
								<div
									className="mt-1"
									style={{fontSize: '0.85rem', color: 'red'}}
								>
									You must agree to create a QUDO account.
								</div>
							)}
						</div>

						<div className="my-4">
							<h5 className="mb-2">
								Contact Permission
							</h5>
							<div
								className="row"
								onClick={() => setMandatoryNewsletter(!mandatoryNewsletter)}
							>
								<input
									type="checkbox"
									className="col-2 my-auto"
									checked={mandatoryNewsletter}
								/>
								<div className="col-10">
									I consent to account-related communications and major announcements.
								</div>
							</div>
							{showNewsletterErrors && !mandatoryNewsletter && (
								<div
									className="mt-1"
									style={{fontSize: '0.85rem', color: 'red'}}
								>
									You must agree to create a QUDO account
								</div>
							)}
							<div
								className="row mt-2"
								onClick={() => setNewsletter(!newsletter)}
							>
								<input
									type="checkbox"
									className="col-2 my-auto"
									checked={newsletter}
								/>
								<div className="col-10">
								I want to receive updates about <b>Airdrops</b>, Giveaways, Challenges and News. (Optional)
								</div>
							</div>
						</div>

						{/*}
						<div className="row mt-1">
							<div className="col-10">
                    			Subscribe to QUDO's newsletter
							</div>
							<input
								type="checkbox"
								className="col-2 my-auto"
								checked={newsletter}
								onChange={() => setNewsletter(!newsletter)}
							/>
						</div>
						{*/}

						<div className="mt-3">
							<ButtonInput
								trigger
								set={submit}
								//disabled={disabled}
								label="Register"
								className="form-submit-btn text-center my-0"
							/>
						</div>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
}
